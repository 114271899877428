<script setup>
import CountToggler from "@/components/base/CountToggler/CountToggler.vue";
import {computed} from "vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";
import {getCurrencySymbol} from "@/helpers";
import {useMenuStore} from "@/store/MenuStore";
const props = defineProps({
  device: {
    type: Object,
    required: true,
  },
});
const cartStore = useCartStore();
const menuStore = useMenuStore();
const {cartItems} = storeToRefs(cartStore);
const cartItemDevice = computed(() => cartItems.value.find((item) => item.product.id === props.device.id))
const cartItemDeviceQuantity = computed(() => cartItemDevice.value ? cartItemDevice.value.quantity : 0);
const deviceTotalPrice = computed(() => props.device.price * cartItemDeviceQuantity.value)

const handleCLickPlus = async () => {
  if (cartItemDevice.value) {
    await cartStore.cartItemUpdate(cartItemDevice.value.id, cartItemDeviceQuantity.value + 1);
  } else {
    await cartStore.addDeviceToCart(props.device, cartItemDeviceQuantity.value + 1);
  }
};

const handleCLickMinus = () => {
  if (cartItemDeviceQuantity.value !== 0 && cartItemDevice.value) {
    cartStore.cartItemUpdate(cartItemDevice.value.id, cartItemDeviceQuantity.value - 1);
  }
};

</script>

<template>
  <v-sheet class="pa-0 w-100">
    <v-sheet class="w-100 d-flex flex-row align-center justify-center ga-2">
      <v-sheet
        class="semibold flex-1-0-0"
      >
        {{ props.device.title }}
      </v-sheet>
      <v-sheet class="d-flex justify-center">
        <count-toggler
          :handle-click-minus="handleCLickMinus"
          :handle-click-plus="handleCLickPlus"
          :value="cartItemDeviceQuantity"
        />
      </v-sheet>
      <v-sheet
        class="semibold "
      >
        {{ deviceTotalPrice ? deviceTotalPrice : props.device.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped></style>
