  <script setup>
  import {storeToRefs} from "pinia";
  import {useMenuStore} from "@/store/MenuStore";
  import {computed, ref, watch} from "vue";
  import RecommendedIngredient from "@/components/Menu/components/RecommendedIngredient.vue";
  import BaseButton from "@/components/base/BaseButton.vue";
  import {useDisplay} from "vuetify";
  import {getCurrencySymbol} from "@/helpers";

  const showAll = ref(false);
  const { ingredientsModalState } = storeToRefs(useMenuStore());
  const { mdAndDown, xs } = useDisplay();
  const menuStore = useMenuStore();
  const selectedModifiers = ref([]);
  const updateSelectedModifier = (modifier, selectedId) => {
    const existingModifier = selectedModifiers.value.find(mod => mod.modifier.id === modifier.id);
    if (existingModifier) {
      existingModifier.selectedId = selectedId;
    } else {
      selectedModifiers.value.push({ modifier: modifier, selectedId });
    }
  };
  const updatedModifiers = computed(() => {
    return product.value.modifiers.map((modifier) => {
      const existingSelection = selectedModifiers.value.find(
          (mod) => mod.modifier.id === modifier.id
      );
      return {
        ...modifier,
        selected: existingSelection ? existingSelection.selectedId : null,
      };
    });
  });
  const product = computed(() => ingredientsModalState.value?.product);
  const selectedIngredients = ref([]);

  const recommendedIngredients = computed(() => ingredientsModalState.value?.product.recommendedIngredients)

  const toggleSelection = (id) => {
    if (selectedIngredients.value.includes(id)) {
      selectedIngredients.value = selectedIngredients.value.filter(item => item !== id);
    } else {
      selectedIngredients.value.push(id);
    }
  };

  const displayedIngredients = computed(() => {
    if (!product.value || !product.value.ingredients) return [];
    return product.value.ingredients.filter(item1 =>
        !recommendedIngredients.value.some(item2 => item1.id === item2.id)
    );
  });

  const toggleShowAll = () => {
    showAll.value = !showAll.value;
  };

  const handleAddProductToCart = () => {
    const modifiers = selectedModifiers.value.map((modifier) => modifier.selectedId)
    menuStore.addProductToCart({...product.value, quantity: 1}, modifiers, updatedIngredientsFoCreation.value);
    menuStore.closeIngredientsModal();
  };

  watch(
      () => ingredientsModalState.value.isOpen,
      (newVal) => {
        if (!newVal) {
          selectedIngredients.value = []; // Сбрасываем при закрытии модального окна
        }
      }
  );

  watch(() => ingredientsModalState.value.isOpen, () => {
    if (!ingredientsModalState.value.isOpen) {
      selectedModifiers.value = [];
      selectedIngredients.value = [];
    }
  })

  const updatedIngredientsFoCreation = computed(() => {
    return selectedIngredients.value.map((ingr) =>  {
      return {
        id: ingr,
        quantity: 1,
      }
    })
  })

  const handleCLickCross = () => {
    menuStore.closeIngredientsModal();
  }

  </script>

<template>
  <v-dialog
    v-model="ingredientsModalState.isOpen"
    scrollable
    opacity="0.8"
    class="align-start overflow-y-scroll"
    width="100%"
    :max-width="mdAndDown ? 'none' : 1000"
    :close-on-back="false"
    :fullscreen="mdAndDown"
    min-height="700px"
    :scroll-strategy="(data) => {
      return 'block'
    }"
  >
    <v-sheet
      v-if="mdAndDown"
      elevation="0"
      class="position-absolute bg-transparent overflow-hidden"
      style="top: 20px; right: 20px; z-index: 1000; width: 34px; height: 34px; min-height: unset; min-width: unset"
    >
      <v-icon
        color="dark"
        class="cursor-pointer text-h4 bg-transparent"
        icon="mdi-close"
        size="x-large"
        @click="handleCLickCross"
      />
    </v-sheet>
    <v-sheet
      v-else
      elevation="0"
      class="position-absolute bg-transparent overflow-hidden"
      style="top: 0; right: -40px; width: 34px; height: 34px"
    >
      <v-icon
        color="white"
        class="cursor-pointer text-h4 bg-transparent"
        icon="mdi-close"
        size="x-large"
        @click="handleCLickCross"
      />
    </v-sheet>
    <v-card
      width="full"
      flat
      :style="{borderRadius: mdAndDown ? '0px' : '50px', scrollbarWidth: 'none'}"
      class="py-8 py-lg-8"
    >
      <v-sheet
        no-gutters
        class=" px-0 d-flex flex-column flex-sm-row align-start flex-grow-1"
      >
        <v-sheet
          class="px-4 w-100 d-flex justify-center align-self-center"
        >
          <v-sheet class="w-100">
            <v-img
              width="100%"
              height="auto"
              min-height="150"
              :src="product.image_full_url"
              cover
              rounded
            />
          </v-sheet>
        </v-sheet>
        <v-sheet
          class="px-4"
          :max-width="xs ? '100%' : '50%'"
        >
          <v-card-title
            class="text-h5 px-0 no-ellipsis font-weight-bold text-uppercase mb-0 pb-0 text-pre-wrap"
          >
            {{ product.title }}
          </v-card-title>

          <v-card-subtitle
            class="mt-0 pa-0 mb-5 text-left text-caption text-black opacity-100"
          >
            {{ product.meta_weight }} г.
          </v-card-subtitle>

          <v-sheet
            v-if="updatedModifiers.length > 0"
            class="d-flex flex-column"
          >
            <v-sheet
              v-for="modifier in updatedModifiers"
              :key="modifier.id"
              class="mb-2"
            >
              <v-select
                v-if="modifier.items"
                v-model="modifier.selected"
                class="font-weight-light text-caption pa-0 "
                :error="updatedModifiers.length !== 0 && selectedModifiers.length === 0"
                tile
                flat
                rounded="2"
                hide-details
                :items="modifier.items"
                density="compact"
                :placeholder="modifier.title"
                variant="outlined"
                item-value="id"
                item-text="title"
                max-width="260"
                @update:model-value="(selectedId) => updateSelectedModifier(modifier, selectedId)"
              >
                <template #selection="{ item }">
                  <v-sheet class="pa-0 font-weight-light text-caption">
                    {{ item.title }}
                  </v-sheet>
                </template>
              </v-select>
            </v-sheet>
          </v-sheet>

          <v-card-text class="text-subtitle-1 custom-line-height px-0 py-0 mb-2 semibold">
            Так еще вкуснее
          </v-card-text>

          <v-sheet class="mb-4 d-flex flex-row ga-2">
            <recommended-ingredient
              v-for="ingredient in recommendedIngredients"
              :key="ingredient.id"
              :ingredient="ingredient"
              :selected="selectedIngredients.find((item) => item === ingredient.id) !== undefined"
              @click="toggleSelection(ingredient.id)"
            />
          </v-sheet>

          <v-sheet>
            <v-card-text
              class=" text-h6 pa-0 cursor-pointer mb-2"
              :class="{
                'text-customRed': !showAll,
                'text-grey-darken-1': showAll
              }"
              @click="toggleShowAll"
            >
              {{ showAll ? 'Скрыть список' : 'Показать полный список' }}
            </v-card-text>
            <v-sheet
              v-if="showAll"
              class="d-flex flex-wrap flex-row align-start"
            >
              <v-checkbox
                v-for="ingredient in displayedIngredients"
                :key="ingredient.id"
                class="pa-0 ma-0 mr-2 checkbox"
                hide-details
                min-height="none"
                density="compact"
                color="#000"
              >
                <template #input>
                  <div
                    class="custom-checkbox pa-0"
                    :class="{
                      'custom-checkbox-checked': selectedIngredients.includes(ingredient.id),
                      'custom-checkbox-disabled': false
                    }"
                    @click="toggleSelection(ingredient.id)"
                  >
                    <v-icon
                      v-if="selectedIngredients.includes(ingredient.id)"
                      color="white"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                </template>
                <template #label>
                  <v-card-text
                    class="custom-checkbox-label semibold pa-0 ma-0"
                    style="color: #000"
                  >
                    {{ ingredient.title }} <span class="font-weight-bold text-black">{{ ingredient.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}</span>
                  </v-card-text>
                </template>
              </v-checkbox>
            </v-sheet>
          </v-sheet>

          <v-card-text class="text-subtitle-2 custom-line-height px-0 py-0">
            {{ product.description }}
          </v-card-text>

          <v-card-text
            style="font-size: 12px"
            class="custom-line-height-2 px-0 pb-2"
          >
            Ккал:&nbsp;{{ product.meta_calories }}, белки:&nbsp;{{
              product.meta_proteins
            }}&nbsp;г., жиры:&nbsp;{{ product.meta_fat }}&nbsp;г.,
            углеводы:&nbsp;{{ product.meta_carbohydrates }}&nbsp;г.
          </v-card-text>

          <v-sheet class="d-flex flex-row align-center ga-16 pt-4">
            <v-sheet
              class="text-h5 semibold custom-line-height px-0 py-0"
            >
              {{ product.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
            </v-sheet>
            <base-button
              max-width="160"
              text="В корзину"
              :uppercase="true"
              :disabled="updatedModifiers.length !== 0 && selectedModifiers.length === 0"
              @click="handleAddProductToCart"
            >
              В КОРЗИНУ
            </base-button>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

  <style scoped>
  input {
    all: unset;
  }

  .custom-checkbox {
    all: unset;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #000;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
    margin-right: 6px;
  }

  .custom-checkbox-checked {
    background-color: #e31d24;
    border-color: #e31d24;
  }

  .custom-checkbox-disabled {
    background-color: #f5f5f5;
    border-color: #dcdcdc;
    cursor: not-allowed;
  }

  .custom-checkbox-label {
    margin-left: 8px;
    font-size: 14px;
  }

  .v-icon {
    font-size: 12px;
  }

  .checkbox >>> label {
    opacity: 1 !important;
  }
  </style>
