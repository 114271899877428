<script setup>
import CartListItem from "@/components/Cart/CartListItem/CartListItem.vue";
import { useCartStore } from "@/store/CartStore";
import { storeToRefs } from "pinia";
import { useMenuStore } from "@/store/MenuStore";
import BaseButton from "@/components/base/BaseButton.vue";
import router from "@/router";
import {useDisplay} from "vuetify";
import {getCurrencySymbol} from "@/helpers";

const cartStore = useCartStore();
const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);
const { selectedDeliveryType, itemsToShow } = storeToRefs(cartStore);

const handleNavigateToCart = () => {
  router.push({
    path: "/",
  });
};
const { xs } = useDisplay();
</script>

<template>
  <v-container
    v-if="
      currentCity && cartStore.cartTotal < currentCity.min_courier_order_amount
    "
    class="d-flex justify-center align-center flex-column ga-4 pa-0 mx-0 mb-4"
  >
    <v-sheet
      v-if="selectedDeliveryType === 'courier'"
      class="background-image-container w-100"
      :class="xs ? 'pa-0' : 'pa-7'"
      rounded="xl"
    >
      <v-card-text class="semibold text-h5 px-0 pb-2">
        Минимальная сумма<br>заказа —
        <span class="text-customRed semibold">{{ currentCity.min_courier_order_amount }}&nbsp;{{ getCurrencySymbol(currentCity) }}</span>
      </v-card-text>
      <v-card-text class="text-h6 pa-0">
        Добавьте что-нибудь ещё
      </v-card-text>
    </v-sheet>
  </v-container>

  <v-container
    v-if="itemsToShow.length === 0"
    class="pa-10 elevation-2 rounded-xl my-4"
  >
    <v-sheet
      width="100%"
      class="d-flex justify-center align-center flex-column"
    >
      <v-card-text class="text-h6 semibold">
        Корзина пуста
      </v-card-text>
      <base-button
        :max-width="260"
        :max-height="40"
        text="НАЗАД К ВЫБОРУ БЛЮД"
        style="font-size: 1rem"
        @click="handleNavigateToCart"
      />
    </v-sheet>
  </v-container>

  <v-sheet
    v-else
    class="d-flex justify-start align-center flex-column ga-7 pa-0 mx-0"
  >
    <CartListItem
      v-for="product in itemsToShow"
      :key="product.id"
      :product="product"
    />
  </v-sheet>
</template>

<style scoped>
.background-image-container {
  background-image: url("@/assets/empty_cart.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
