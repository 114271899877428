<script setup>
  import {boolean} from "yup";
  import {getCurrencySymbol} from "@/helpers";
  import {useMenuStore} from "@/store/MenuStore";

  const props = defineProps({
    ingredient: {
      type: Object,
      required: true
    },
    selected: {
      type: boolean,
      required: true,
      default: false,
    }
  })

  const menuStore = useMenuStore();
</script>

<template>
  <v-sheet
    rounded="sm"
    class="item pa-2 d-flex flex-column align-center cursor-pointer position-relative"
    width="120px"
    height="auto"
  >
    <v-img
      width="100%"
      height="75"
      max-height="75"
      class="mb-2"
      :src="props.ingredient.image_url"
    />
    <v-sheet
      class="text-center mt-2 mt-auto"
      style="line-height: 1.2"
    >
      {{ props.ingredient.title }}
    </v-sheet>
    <v-sheet
      class="bold"
      style="font-size: 16px"
    >
      {{ props.ingredient.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
    </v-sheet>
    <v-sheet
      v-if="selected"
      class="item w-100 h-100 position-absolute top-0 left-0 d-flex flex-column align-center justify-center text-h4 ga-2 opacity-50 bg-black"
    >
      <v-sheet class="bold bg-transparent">
        X1
      </v-sheet>
      <v-sheet class="bold bg-transparent">
        <v-icon
          icon="mdi-trash-can-outline"
          size="x-small"
        />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.item {
  border: 1px solid #aaaaaa;
  border-radius: 22px!important;
  transition: all .3s;
  font-size: 13px;
  &:hover {
    border: 1px solid #e41d30;
  }
}
</style>
